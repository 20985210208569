import "./course_bookings";
import "./course_booking_customers";
import "./course_booking_instructors";
import "./documents";
import "./expenses";
import "./fts_courses";
import "./invoices";
import "./layouts";
import "./locations";
import "./payments";
import "./shipments";
import "./users";