const handleRemovePaymentModal = () => {
  const removePaymentModal = document.getElementById("remove-payment-modal");
  if (!removePaymentModal) return;

  removePaymentModal.addEventListener("show.bs.modal", (e) => {
    const triggerEl = e.relatedTarget;
    const tableRow = triggerEl.closest("tr");

    const { paymentId } = tableRow.dataset;
    console.log("paymentId", paymentId);

    const amount = tableRow.querySelector(".payment-amount").innerText;
    const paymentDate = tableRow.querySelector(".payment-date").innerText;

    removePaymentModal.querySelector('.payment-amount').innerHTML = amount;
    removePaymentModal.querySelector('.payment-date').innerHTML = paymentDate;
    removePaymentModal.querySelector('#remove-payment-modal-confirm').href = `/payments/${paymentId}`;
  });
};

document.addEventListener("turbolinks:load", () => {
  handleRemovePaymentModal();
});