import { addDatePickerToForm } from "../../common";

const updateCourseInstructors = () => {
  const sectionEl = document.getElementById("course_booking_instructor_section");
  const instructorContainer = document.getElementById("instructor-select");
  const instructorSelectEl = document.getElementById("course_booking_instructor_user_id");
  let instructors;

  instructorSelectEl.options.length = 0;

  switch (sectionEl.value) {
    case "frontend":
      instructors = instructorContainer.dataset.instructorsFrontend;
      break;
    case "backend":
      instructors = instructorContainer.dataset.instructorsBackend;
      break;
    default:
      instructors = instructorContainer.dataset.instructorsBoth;
  }

  JSON.parse(instructors).forEach(instructor => {
    instructorSelectEl.options.add(new Option(instructor.name, instructor.user_id))
  });

};

export const handleSectionChange = () => {
  const sectionEl = document.getElementById("course_booking_instructor_section");
  if (!sectionEl) return;

  updateCourseInstructors();

  sectionEl.addEventListener("change", updateCourseInstructors);
};

document.addEventListener("turbolinks:load", () => {
  handleSectionChange();

  addDatePickerToForm(document.querySelector("input#course_booking_instructor_training_start_date"));

  document.getElementById("change_instructor_form")?.addEventListener('shown.bs.modal', (e) => {
    updateCourseInstructors();
  });
});